/*　include
------------------------------------------------------- */

import "core-js/stable";
import "regenerator-runtime/runtime";
import './scss/style.scss';

/*
------------------------------------------------------- */

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $("#logo").addClass("fadeIn");
    $("#btn01").addClass("fadeIn");
  }, 2750);
});

document.addEventListener('DOMContentLoaded', (event) => {
let splashWrapA = CSSRulePlugin.getRule(".el_splash");
let splashAfter = CSSRulePlugin.getRule(".el_splash:after");
let splashTlA = new TimelineMax({
  delay: 0,
})
.to(splashAfter,0.8,{ease:Power3.easeInOut,cssRule:{scaleX:0}}, 'gate')
.to(splashAfter,1,{cssRule:{display:"none"}}, 'gate');
});

document.addEventListener('DOMContentLoaded', (event) => {
let splashWrap = CSSRulePlugin.getRule(".el_splash");
let splashBefore = CSSRulePlugin.getRule(".el_splash:before");
let openingTtl = CSSRulePlugin.getRule(".el_splash_inner_logo_ttl");
let splashTl = new TimelineMax({
  delay: 1,
})
.to(splashBefore,1,{ease:Power3.easeInOut,cssRule:{scaleX:0}}, 'gate')
.to(splashWrap,1,{cssRule:{display:"none"}}, 'gate');
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $(".el_common_ttl").addClass("fadeInB");
  }, 2780);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $(".el_common_ttl").addClass("fadeIn");
  }, 1700);
});

$(function() {
$('.js_header_btn').hover(function() {
$(this).css('transform', 'scale(0.9)')
}, function() {
$(this).css('transform', 'scale(1.0)')
});
});

$(function() {
  $("#btn01,header.ly_header .ly_header_inner .un_closeArea").on("click", function() {
    $('.ly_header').toggleClass('active-sp')
  });
});

$(document).ready(function() {
let toggleButton = document.querySelector('.js_header_btn');
toggleButton.addEventListener('click',function(){
    let target = this.nextElementSibling;
    const result = target.classList.contains('open');
    if (result) {
        target.classList.remove('open');
        gsap.to(target,{
            width: '0vw',
            duration:0.5,
            ease: "power2.inOut"
        });
    }else{
        target.classList.add('open');
        gsap.to(target,{
            width:'100vw',
            duration:0.5,
            ease: "power2.inOut"
        });
    }
})
});

$(document).ready(function() {
let closeArea = document.querySelector('.un_closeArea');
closeArea.addEventListener('click',function(){
    let target = document.querySelector('.ly_header_inner');
    const result = target.classList.contains('open');
    if (result) {
        target.classList.remove('open');
        gsap.to(target,{
            width: '0vw',
            duration:0.5,
            ease: "power2.inOut"
        });
    }else{
        target.classList.add('open');
        gsap.to(target,{
            width:'100vw',
            duration:0.5,
            ease: "power2.inOut"
        });
    }
})
});

$.fn.clickToggle = function (a, b) {
  return this.each(function () {
    var clicked = false;
    $(this).on('click', function () {
      clicked = !clicked;
      if (clicked) {
        return a.apply(this, arguments);
      }
      return b.apply(this, arguments);
    });
  });
};

const mediaQuery = window.matchMedia('(max-width: 768px)');
handle(mediaQuery);
mediaQuery.addListener(handle);
function handle(mm) {
  if (mm.matches) {
$(function(){
  $('.js_header_btn').on('click', function() {
    $(this).toggleClass('active');
    return false;
  });
  $(".un_closeArea").on("click", function() {
    $(".js_header_btn").removeClass("active");
  });
$("#btn01").on("click", function() {
  setTimeout(function() {
  $(".ly_header_inner_left").toggleClass("sp-open");
  $(".ly_header_inner_right").toggleClass("sp-open");
  $("#logo.sp").toggleClass("sp-open");
  $(".ly_header_inner_sns").addClass("sp-open");
  $(".ly_header_inner_sns").removeClass("sp-close");
  },550);
});
$("#btn01").on("click", function() {
  $(".ly_header_inner_sns").removeClass("sp-open");
  $(".ly_header_inner_sns").addClass("sp-close");
});
$(".un_closeArea").on("click", function() {
  $(".ly_header_inner_left").removeClass("sp-open");
  $(".ly_header_inner_right").removeClass("sp-open");
  $(".ly_header_inner_sns").removeClass("sp-open");
  $("#logo.sp").removeClass("sp-open");
});
});
  } else {
$(".js_header_btn").on("click", function() {
  setTimeout(function() {
    $(".ly_header_inner_left").removeClass("hidden");
    $(".ly_header_inner_sns").removeClass("hidden");
    $(".ly_header_inner_right").removeClass("hidden");
  },550);
  setTimeout(function() {
    $(".ly_header_inner_right").addClass("visible");
  },350);
  setTimeout(function() {
    $(".ly_header_inner_left").addClass("visible");
    $(".ly_header_inner_sns").addClass("visible");
  },900);
});
$(".un_closeArea").on("click", function() {
  $(".ly_header_inner_left").removeClass("visible");
  $(".ly_header_inner_right").removeClass("visible");
  $(".ly_header_inner_left").addClass("hidden");
  $(".ly_header_inner_sns").addClass("hidden");
  $(".ly_header_inner_sns").removeClass("visible");
});
  }
}

$(".news ul.bl_main_link-a").on("click", function() {
  $(this).children('.content').slideToggle();
  $(this).toggleClass('open');
});

if (matchMedia('(max-width: 768px)').matches) {
  // ウィンドウサイズが768px以下のとき
    document.addEventListener('click', (e) => {
  if(!e.target.closest('.js_sort-hover')) {
    $('.top .js_tab-group.visible').removeClass('visible');
    console.log('clicked');
  } else {
    //ここに内側をクリックしたときの処理
  }
})
} else {
  // それ以外
}

$("ul.bl_main_link-a li.link .btn").hover(function(){
  $(this).parent().parent().parent().find(".line").addClass("hover");
}, function() {
  $(this).parent().parent().parent().find(".line").removeClass("hover");
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.ly_about_top h3').addClass('move');
  }, 2000);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.ly_about_top .p-wrap:nth-of-type(1) p').addClass('move');
  }, 2600);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.ly_about_top .p-wrap:nth-of-type(2) p').addClass('move');
  }, 3100);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.ly_about_top .p-wrap:nth-of-type(3) p').addClass('move');
  }, 3600);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.ly_about_top p.cap').addClass('move');
  }, 4400);
});

$(window).on('scroll', function (){
  var elem = $('.about .ly_company_wrap');
  var isAnimate = 'fadeInC';
  elem.each(function () {
    var elemOffset = $(this).offset().top;
    var scrollPos = $(window).scrollTop();
    var wh = $(window).height();
    if(scrollPos > elemOffset - wh + (wh / 7) ){
      $(this).addClass(isAnimate);
    }
  });
});

var touch = 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

if(touch) {
  try {
    for (var si in document.styleSheets) {
      var styleSheet = document.styleSheets[si];
      if (!styleSheet.rules) continue;

      for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
        if (!styleSheet.rules[ri].selectorText) continue;

        if (styleSheet.rules[ri].selectorText.match(':hover')) {
          styleSheet.deleteRule(ri);
        }
      }
    }
  } catch (ex) {}
}
